<!-- 智慧安全/宿舍查寝系统/查寝系统 -->
<template>
    <Building />
</template>
<script>
    import Building from './Building.vue';
    export default {
        name: "SSafeRoomCheck",
        components: {
            Building
        }
    }
</script>