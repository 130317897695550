<!-- 智慧安全/校园监控系统/校内监控 -->
<template>
    <div style="display: flex;align-items:center;justify-content:center;height: 100%">
        <video :src="src" controls autoplay loop width="80%" style="margin-top: -60px"></video>
    </div>
</template>
<script>
export default {
    name: "SSafeMonitorMonitor",
    data() {
        return {
            src: 'http://124.71.235.241:9000/cloud-campus-yanshi/11%E6%9C%8824%E6%97%A5%20%281%29.mp4'
        }
    }
}
</script>