<!-- 智慧安全/校园监控系统/电子围栏 -->
<template>
    <Building />
</template>
<script>
import Building from './Building.vue';
export default {
    name: "SSafeMonitorLan",
    components: {
        Building
    }
}
</script>