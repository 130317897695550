<!-- 智慧安全/校园监控系统/视频监控 -->
<template>
    <s-safe-monitor-video-surveillance-scroll-wrapper></s-safe-monitor-video-surveillance-scroll-wrapper>
</template>

<script>
import SSafeMonitorVideoSurveillanceScrollWrapper from "@/components/scrollWrapper/SSafeMonitorVideoSurveillance.vue";

export default {
    name: "SSafeMonitorVideoSurveillance",
    components: { SSafeMonitorVideoSurveillanceScrollWrapper },
}
</script>

<style scoped>

</style>
