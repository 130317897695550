<!-- 智慧安全/出入校管理系统/访客系统 -->
<template>
    <Building />
</template>
<script>
import Building from './Building.vue';
export default {
    name: "SSafeInoutGuest",
    components: {
        Building
    }
}
</script>