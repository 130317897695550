<!-- 校内安保 > 出入设置 -->
<template>
    <div class="main-page">
        <template v-if="isShowTab">
            <tabbed-page
                :tabList="tabList"
                @changeTabs="changeTabs"
            >
            </tabbed-page>
        </template>
        <div class="page-box">
            <component
                ref='component'
                :is="comps[idx]"
                @switchClick="switchClick"
                @checkingInIdChange="checkingInIdChange"
                @routerReturnChange="routerReturnChange"
                :checkingInId="checkingInId"
                :routerReturn="routerReturn"
                @changeShowTab="changeShowTab"
                @setPageInitTabs="setPageInitTabs"
            >
            </component>
        </div>
    </div>
</template>
<script>
import TabbedPage from "../components/scrollWrapper/Sub/TabbedPage/index.vue";
import {tabPageHasAuth} from "@/libs/tabbedPageAuth.js";
export default {
    name: "SSafeInoutConfig",
    components: {
        CampusSafetyAccessManageAccessSetting: () =>
            import(
                /* webpackChunkName:'CampusSafetyAccessManageAccessSetting' */ "../components/scrollWrapper/CampusSafetyAccessManageAccessSetting.vue"
            ),
        InterconnectionParentDirectory: () =>
            import(
                /* webpackChunkName:'InterconnectionParentDirectory' */ "../components/scrollWrapper/InterconnectionParentDirectory.vue"
            ),
        TabbedPage,
    },
    data() {
        return {
            type: 1,
            isShowTab: true,
            types: "list",
            currentComponent: "",
            checkingInId: undefined,
            routerReturn: undefined,
            comps: [
                "CampusSafetyAccessManageAccessSetting",
                "InterconnectionParentDirectory",
            ],
            tabList: [
                {
                    id: 0,
                    title: "出入组管理",
                    sel: true,//无权限添加默认选中
                    com: "CampusSafetyAccessManageAccessSetting",
                },
                {
                    id: 1,
                    title: "接送管理",
                    sel: false,
                    com: "InterconnectionParentDirectory",
                },
            ],
            idx: 0,
        };
    },
    activated() {
        this.setGlobalPageType(this.types);
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.tabList = tabPageHasAuth(this.tabList);
            this.comps = this.tabList.map((item) => item.com);
            console.log(this.tabList, this.comps, "this.tabList");
        },
        /**
         * @Description: 切换tab
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-08-16 10:01:06
         */
        changeTabs(data) {
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, "sel", true);
                    this.idx = index;
                } else {
                    this.$set(item, "sel", false);
                }
            });
        },
        switchClick(page) {
            this.currentComponent = page;
        },
        checkingInIdChange(id) {
            this.checkingInId = id;
        },
        routerReturnChange(path) {
            this.routerReturn = path;
        },
        changeShowTab(msg) {
            this.isShowTab = msg;
            if (this.isShowTab) {
                this.setGlobalPageType("list");
            } else {
                this.setGlobalPageType("form");
            }
        },
        setGlobalPageType(type) {
            this.types = type;
            this.$eventDispatch("setGlobalPageType", type);
        },
        setPageInitTabs() {
            this.$refs.component.showAddEdit = false;
            this.isShowTab = true;
            this.setGlobalPageType("list");
            this.idx = 0;
        }
    },
};
</script>
<style lang="scss" scoped>
.tab-switch {
    margin-bottom: 10px;
    margin-top: 1px;
    display: flex;
    padding-left: 10px;
    border-radius: 5px;
    align-items: center;
    background-color: #ffffff;

    span {
        cursor: pointer;
        margin: 0 20px;
        font-size: 14px;
        line-height: 50px;
    }

    .item-ok {
        color: #3064bf;
        line-height: 48px;
        border-bottom: solid 2px #3064bf;
    }
}
</style>
