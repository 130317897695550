<template>
    <!--出入统计-->
    <SSafeInoutStatisticsScrollWrapper />
  </template>
  
  <script>
  import SSafeInoutStatisticsScrollWrapper from '@/components/scrollWrapper/SSafeInoutStatistics.vue'
  export default {
    name: "SSafeInoutStatistics",
    components: {
        SSafeInoutStatisticsScrollWrapper
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  