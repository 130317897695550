<template>
    <!-- 校内监控/设备列表 -->
    <SSafe-monitor-equipment-list-scroll-wrapper></SSafe-monitor-equipment-list-scroll-wrapper>
</template>
<script>
import SSafeMonitorEquipmentListScrollWrapper from '@/components/scrollWrapper/SSafeMonitorEquipmentList.vue';
export default {
    name: "SSafeMonitorEquipmentList",
    components: {
        SSafeMonitorEquipmentListScrollWrapper
    }
}
</script>
